<template>
  <div>
    <h1 class="heading-lg pb-8 px-5 md:px-6 lg:px-12">Tillsatta jobb</h1>
    <ul class="w-full border-t-[0.5px] border-white">
      <li
        v-for="job in archivedJobs"
        :key="job.id"
        class="w-full flex flex-col ml-auto py-4 md:py-8 border-b-[0.5px] border-white bg-black current-jobs group px-5 md:px-6 lg:px-12"
      >
        <span
          class="border-[0.5px] border-green text-green max-w-[100px] inline body-sm py-0.5 md:py-1.5 px-2 text-center"
          >Tillsatt</span
        >
        <h3 class="heading-sm pt-2 md:pt-6">
          {{ job.attributes.title }}
        </h3>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      archivedJobs: []
    }
  },
  mounted() {
    this.fetchArchivedJobs()
  },
  methods: {
    async fetchArchivedJobs() {
      try {
        let currentPage = 1
        let allArchivedJobs = []

        // Hämta alla sidor av arkiverade jobb
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const response = await axios.get(
            `https://api.teamtailor.com/v1/jobs?filter[status]=archived&page=${currentPage}`,
            {
              headers: {
                Authorization: 'Token token=z9psHiUjK0U1QVGKBkyv2gGvhnxNqlic4BApKy2n',
                'X-Api-Version': '20240101',
                Accept: 'application/vnd.api+json'
              },
              params: {
                archived: true // Filter för att hämta bara arkiverade jobb
              }
            }
          )
          const pageArchivedJobs = response.data.data

          // Om det inte finns fler arkiverade jobb, bryt loopen
          if (pageArchivedJobs.length === 0) break

          allArchivedJobs = allArchivedJobs.concat(pageArchivedJobs)
          currentPage++
        }

        this.archivedJobs = allArchivedJobs
      } catch (error) {
        console.error('Error fetching archived jobs:', error)
      }
    }
  }
}
</script>
